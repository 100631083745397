<template>
  <div class="products">
    <div class="product-body">
      <b-alert
        v-if="!rows.length"
        show
        variant="warning"
        class="text-center m-2"
      >
        <h5>Bilgi</h5>
        <hr />
        <p>Gösterilecek veri yok</p>
      </b-alert>

      <div class="p-2" v-else>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :line-numbers="true"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :expandOptions="{ enabled: true, selfManaged: true }"
          :expandedRows="[1, 4, 12, 54]"
          styleClass="vgt-table striped bordered"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'uye'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.rez_k_no },
                }"
              >
                <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                  {{ props.row.uye_k_no }}</b-badge
                >
                <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                  {{ props.row.uye_ad }} {{ props.row.uye_soyad }}
                </b-badge>
                <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                  {{ props.row.uye_e_mail }}
                </b-badge>
                <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                  {{ props.row.uye_telefon }}
                </b-badge>
              </router-link>
            </span>
            <span v-if="props.column.field === 'islem_tarihi'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.rez_k_no },
                }"
              >
                <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
                  {{ props.row.tarih | momentFull }}</b-badge
                >
              </router-link>
            </span>
            <span v-if="props.column.field === 'iptal_notu'">
              <router-link
                :to="{
                  name: 'rezervasyon-detay',
                  params: { k_no: props.row.rez_k_no },
                }"
              >
                <b-badge variant="" class="mt-1 rounded-0 text-left">
                  {{ props.row.rez_k_no }}</b-badge
                ><br />
                {{ props.row.iptal_notu }}
              </router-link>
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <b-form-checkbox
                class="custom-control-info"
                v-model="props.row.statu"
                switch
                @change="handlerStatu(props.row.k_no, $event)"
                size="lg"
              />
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="float-right px-1"
            >
              <b-dropdown
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                menu-class="w-100"
              >
                <template #button-content>
                  <i class="fad fa-ellipsis-v-alt"></i>
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'rezervasyon-detay',
                    params: { k_no: props.row.rez_k_no },
                  }"
                >
                  <i class="fad fa-eye" /> Detay
                </b-dropdown-item>

                <b-dropdown-item @click="handlerRemove(props.row.k_no)">
                  <i class="fad fa-trash" /> Sil</b-dropdown-item
                >
              </b-dropdown>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="expanded-row" slot-scope="props">
            <div>
              {{ props.row }}
            </div>
          </template>
          <template slot="pagination-bottom">
            <Pagination
              :urunler="rows"
              :count="count"
              @handlerPageSizeChange="handlerPageSizeChange($event)"
              @handlerPageChange="handlerPageChange($event)"
            />
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import { useToast } from "vue-toastification/composition";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";

import Pagination from "../component/listele/Pagination.vue";
import { useRouter } from "@/libs/utils";
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    Pagination,
  },
  props: {
    filtered: [Boolean],
    itemView: [String],
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.pageLength = ref(20);
    expo.perPageOptions = ref([10, 20, 50, 100]);
    expo.columns = ref([
      {
        label: "Uye",
        field: "uye",
        width: "12%",
      },
      {
        label: "İşlem Tarihi",
        field: "islem_tarihi",
        width: "9%",
      },
      {
        label: "İptal Notu",
        field: "iptal_notu",
      },
      {
        label: "Statü",
        field: "statu",
        width: "10%",
        tdClass: "text-right",
      },
      {
        field: "action",
        width: "3%",
      },
    ]);

    expo.rows = ref([]);
    expo.count = ref(0);

    expo.handlerFetchAllData = async () => {
      context.emit("show", true);
      context.emit("pageTitle", "İptal Talepleri");

      route.value.query.page = route.value.query.page
        ? parseInt(route.value.query.page)
        : 1;
      route.value.query.page_size = route.value.query.page_size
        ? parseInt(route.value.query.page_size)
        : 20;

      await store
        .dispatch("iptalTalepListele", route.value.query)
        .then((res) => {
          expo.rows.value = res.data.data;
          expo.count.value = res.data.count;
          context.emit(
            "total",
            `${expo.count.value} kayıttan ${expo.rows.value.length} `
          );
          context.emit("show", false);
          // context.emit('filtered', false);
        });
    };

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    expo.handlerStatu = (k_no, statu) => {
      store.dispatch("iptalTalepStatuGuncelle", { k_no, statu }).then((res) => {
        if (res.data.success === true) {
          if (statu) {
            toast.success("Tamamlandı", { position: "bottom-left" });
          } else {
            toast.error("İşlem Geri Alındı.", { position: "bottom-left" });
          }
        }
      });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: "Uyarı",
        text: "Kayit Silinecektir",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch("iptalTalepSil", { k_no }).then((res) => {
            if (res.data.success === true) {
              toast.error("Silme Başarılı", { position: "bottom-left" });

              let index = expo.rows.value.findIndex((x) => x.k_no == k_no);
              expo.rows.value.splice(index, 1);
            }
          });
        }
      });
    };

    watch(
      route,
      (val) => {
        expo.handlerFetchAllData();
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
